import { render, staticRenderFns } from "./PhoneVerificationForm.vue?vue&type=template&id=22c21da0&"
import script from "./PhoneVerificationForm.vue?vue&type=script&lang=js&"
export * from "./PhoneVerificationForm.vue?vue&type=script&lang=js&"
import style0 from "./PhoneVerificationForm.vue?vue&type=style&index=0&id=22c21da0&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports