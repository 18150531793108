var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.pageData)?_c('div',{staticClass:"competition_content_wrapper"},[_c('Breadcrumb',{attrs:{"breadcrumbOptions":_vm.breadcrumbData}}),_c('div',{staticClass:"conteiner"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-5"},[_c('div',{staticClass:"question_card"},[_c('h5',{staticClass:"question_title"},[_vm._v("سؤال الشهر")]),_c('h3',{staticClass:"question_desc"},[_vm._v(_vm._s(_vm.pageData.question))])]),(!_vm.pageData.is_answer)?_c('div',{staticClass:"answer_wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.answer),expression:"answer",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","placeholder":"الإجابة"},domProps:{"value":(_vm.answer)},on:{"input":function($event){if($event.target.composing)return;_vm.answer=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('button',{staticClass:"answer_code_btn",attrs:{"type":"button"},on:{"click":_vm.validateFormInputs}},[_vm._v(" "+_vm._s(_vm.$t("BUTTONS.send"))+" ")])]):_c('div',{staticClass:"answer_hint"},[_vm._v(" لقد قمت بالإجابة علي هذا السؤال ")])])]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-6 mt-4"},[_c('h4',{staticClass:"winners_title"},[_vm._v(" قائمة الفائزين بشهر "+_vm._s(_vm.pageData.month)+" ")]),_c('div',{staticClass:"row w-100"},[_c('div',{staticClass:"col-lg-4 my-3"},[_c('div',{staticClass:"winner_card"},[_c('h5',{staticClass:"winner_position"},[_vm._v("المركز الأول")]),_c('div',{staticClass:"image_wrapper"},[_c('img',{attrs:{"src":_vm.pageData.previous_month_winners
                                            .first_winner.image_url,"alt":_vm.pageData.previous_month_winners
                                            .first_winner.name,"width":"100","height":"100"}})]),_c('h4',{staticClass:"winner_name"},[_vm._v(" "+_vm._s(_vm.pageData.previous_month_winners .first_winner.name)+" ")]),_c('h6',{staticClass:"prize"},[_vm._v(" "+_vm._s(_vm.pageData.previous_month_winners .first_winner.prize)+" ")])])]),_c('div',{staticClass:"col-lg-4 my-3"},[_c('div',{staticClass:"winner_card"},[_c('h5',{staticClass:"winner_position"},[_vm._v("المركز الثاني")]),_c('div',{staticClass:"image_wrapper"},[_c('img',{attrs:{"src":_vm.pageData.previous_month_winners
                                            .second_winner.image_url,"alt":_vm.pageData.previous_month_winners
                                            .second_winner.name,"width":"100","height":"100"}})]),_c('h4',{staticClass:"winner_name"},[_vm._v(" "+_vm._s(_vm.pageData.previous_month_winners .second_winner.name)+" ")]),_c('h6',{staticClass:"prize"},[_vm._v(" "+_vm._s(_vm.pageData.previous_month_winners .second_winner.prize)+" ")])])]),_c('div',{staticClass:"col-lg-4 my-3"},[_c('div',{staticClass:"winner_card"},[_c('h5',{staticClass:"winner_position"},[_vm._v("المركز الثالث")]),_c('div',{staticClass:"image_wrapper"},[_c('img',{attrs:{"src":_vm.pageData.previous_month_winners
                                            .third_winner.image_url,"alt":_vm.pageData.previous_month_winners
                                            .third_winner.name,"width":"100","height":"100"}})]),_c('h4',{staticClass:"winner_name"},[_vm._v(" "+_vm._s(_vm.pageData.previous_month_winners .third_winner.name)+" ")]),_c('h6',{staticClass:"prize"},[_vm._v(" "+_vm._s(_vm.pageData.previous_month_winners .third_winner.prize)+" ")])])])]),_c('div',{staticClass:"route_wrapper"},[_c('router-link',{attrs:{"to":"/all-winners"}},[_vm._v(" فائزين الأشهر الماضية ")])],1)])])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }