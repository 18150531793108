var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"products_carousel_wrapper",attrs:{"dir":"ltr"}},[(_vm.carouselItems != '')?_c('carousel',{attrs:{"nav":true,"dots":false,"items":4,"margin":15,"loop":false,"autoplay":true,"autoplayTimeout":6000,"dragEndSpeed":2000,"smartSpeed":2000,"responsive":{
      0: {
        items: 2,
        nav: false,
      },
      750: {
        items: 2,
        nav: false,
      },
      900: {
        items: 4,
      },
    }}},_vm._l((_vm.carouselItems),function(item){return _c('div',{key:item.id,staticClass:"carousel_item_wrapper",attrs:{"dir":_vm.getAppLocale == 'ar' ? 'rtl' : 'ltr'}},[_c('ProductCard',{attrs:{"productDetails":item}})],1)}),0):_c('EmptyItemsProducts')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }